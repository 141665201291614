exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-templates-category-blog-js": () => import("./../../../src/templates/category/blog.js" /* webpackChunkName: "component---src-templates-category-blog-js" */),
  "component---src-templates-category-loadmore-js": () => import("./../../../src/templates/category/loadmore.js" /* webpackChunkName: "component---src-templates-category-loadmore-js" */),
  "component---src-templates-category-promotion-js": () => import("./../../../src/templates/category/promotion.js" /* webpackChunkName: "component---src-templates-category-promotion-js" */),
  "component---src-templates-page-default-js": () => import("./../../../src/templates/page/default.js" /* webpackChunkName: "component---src-templates-page-default-js" */),
  "component---src-templates-page-demo-games-js": () => import("./../../../src/templates/page/demo_games.js" /* webpackChunkName: "component---src-templates-page-demo-games-js" */),
  "component---src-templates-page-live-game-js": () => import("./../../../src/templates/page/live_game.js" /* webpackChunkName: "component---src-templates-page-live-game-js" */),
  "component---src-templates-page-manual-js": () => import("./../../../src/templates/page/manual.js" /* webpackChunkName: "component---src-templates-page-manual-js" */),
  "component---src-templates-page-register-js": () => import("./../../../src/templates/page/register.js" /* webpackChunkName: "component---src-templates-page-register-js" */),
  "component---src-templates-post-regular-js": () => import("./../../../src/templates/post/regular.js" /* webpackChunkName: "component---src-templates-post-regular-js" */)
}

